/*-------------------------
    Banner
-------------------------*/

.facebook {
zoom: 500%;
}



.lol {
    margin-left: 170px;
    margin-top: -30px;
    margin-bottom: 50px;   
}
@media (max-width: 900px) {
    .lol {
        margin: 0 auto;
        top: 50%;
        left: 10%;
    }
}

.lol2 {
    margin-left: 65px;
    margin-top: -70px;
    margin-bottom: -15px; 
    transform: scale(0.7);
}

@media (min-width: 1900px) { .lol {
    margin-left: 225px;
    margin-top: -30px;
    margin-bottom: 40px;   
}}

.g-blue{ color:#4285F4; }
.o-red{ color:#DB4437; }
.o-yellow{ color:#F4B400; }
.l-green{ color:#0F9D58; }
.e-red { display:inline-block;transform:rotate(-20deg); }
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #0f0f0f;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(238, 15, 15);
    border-radius: 50px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(155, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }

html {
    overflow-x: hidden;
}

@media (max-width: 1300px) {
    .banner 
         img {
            margin-top: -60px;
             max-width: 140%;
             margin-left: -20%;
         }
}
.banner 
    img {

         max-width: 160%;
         margin-left: -40%;
        
 }

 @media (max-width: 1100px) {
    .facebook {     
        margin-left: -10px!important;
        margin-top: 10%!important;
        margin-bottom: 5px;
        top: 50%;
        transform: scale(0.8);
    }
 }

.banner {

    
    padding: 255px 0 0;
    position: relative;
    z-index: 2;
    min-height: 800px;
    @media #{$laptop-device} {
        padding: 170px 0 0;
    }
    @media #{$smlg-device} {
        padding: 200px 0 0;
        min-height: 100%;
    }
    
    @media #{$md-layout} {
        padding: 160px 0 0;
    } 
    @media #{$sm-layout} {
        padding: 150px 0 0;
    }

    .banner-content {
        @media #{$md-layout}{
            margin-bottom: 90px;
        }
        @media #{$sm-layout}{
            margin-bottom: 60px;
        }
        @media #{$large-mobile}{
            margin-bottom: 40px;
        }
        .title {
            font-size: 90px;
            font-weight: 700;
            color: var(--color-dark);
            @media #{$laptop-device} {
                font-size: 98px;
            }
            @media #{$smlg-device} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 55px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 40px;
            width: 90%;
        }
        .axil-btn {
            padding: 28px 45px;
            box-shadow: 0 44px 84px -24px #5956e9;
            @media #{$md-layout} {
                padding: 20px 35px;
            }
        }
        .btn-group {
            display: flex;
            align-items: center;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            a {
                margin-right: 40px;
                @media #{$large-mobile} {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
        .about-btn {
            font-weight: 500;
            color: var(--color-primary);
            position: relative;
            padding-left: 50px;
            &::before {
                content: "";
                height: 1px;
                width: 34px;
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                transition: var(--transition);
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
    }
    .banner-thumbnail {
        position: relative;
    }
    .banner-social {
        display: flex;
        align-items: center;
        .border-line {
            flex: 1;
            height: 1px;
            width: 100%;
            background-color: var(--color-body);
            margin-right: 30px;
            @media #{$large-mobile} {
                display: none;
            }
        }
        .social-icon {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: var(--color-body);
                    i, svg {
                        margin-right: 8px;
                        font-size: 20px;
                        color: var(--color-gray-4);
                        transition: var(--transition);
                    }
                    &:hover {
                        color: var(--color-primary);
                        i {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    &.banner-style-1 {
        .banner-content {
            margin-top: 10px;
            @media #{$smlg-device} {
                padding-bottom: 100px;
            }
            @media #{$md-layout} {
                margin-top: 0;
                padding-bottom: 0;
            }
            .title {
                font-size: 78px;
                line-height: 1;
                @media #{$laptop-device} {
                    font-size: 68px;
                }
                @media #{$smlg-device} {
                    font-size: 60px;
                }
                @media #{$md-layout} {
                    font-size: 55px;
                }
                @media #{$sm-layout} {
                    font-size: 40px;
                }
            }
        }
        .banner-thumbnail {
            z-index: 5;
            .large-thumb {
                @media #{$laptop-device} {
                    img {
                        max-width: 160%!important;
                        zoom: 60%;
                    }
                }
                @media #{$sm-layout} {
                    img {
                        max-width: 160%!important;
                        zoom: 60%;
                    }
                    // display: none;
                }
            }
            .large-thumb-2 {
                text-align: right;
                margin-top: -260px;
                margin-right: -50px;
                @media #{$laptop-device} {
                    img {
                        width: 85%;
                    }
                }
                @media #{$smlg-device} {
                    margin-top: -200px;
                    margin-right: -30px;
                    img {
                        width: 80%;
                    }
                }
                @media #{$md-layout} {
                    margin-top: -260px;
                    margin-right: 0;
                }
                @media #{$sm-layout} {
                    margin-top: 0;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                }
            }
            .shape-group {
                .shape {
                    position: absolute;
                    &.shape-1 {
                        top: -25px;
                        right: 20px;
                        left: auto;
                        @media #{$laptop-device} {
                            right: 0;
                        }
                        @media #{$smlg-device} {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.banner-style-2 {
        
        margin: 160px 95px 0;
        padding: 250px 0 300px 200px;
        background: linear-gradient(45deg,#000000, #3f3f3f, #020202 );
        background-size: 600% 100%;
        animation: gradient 16s linear infinite;
        animation-direction: alternate;
        border-radius: 60px;
        overflow: hidden;
        
        @media #{$laptop-device} {
            padding: 150px 0 200px 100px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 150px 60px;
            min-height: 600px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 80px 30px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        @keyframes gradient {
            0% {background-position: 0%}
            100% {background-position: 100%}
        }

        .banner-content {
            margin-bottom: 0;
            .title {
                margin-top: -80px;
                color: var(--color-white);
                margin-bottom: 30px;
                font-size: 57pt;
            }
            .subtitle {
                color: var(--color-white);
                margin-bottom: 40px;
                font-size: 13pt;
                font-weight: 500;
            }
        }
        @media (max-width: 900px) { 
            .hidemobile {
                display: none;
            }

            .banner-content {
                margin-bottom: 0;
                .title {
                    margin-top: 00px;
                    color: var(--color-white);
                    margin-bottom: 00px;
                    font-size: 34pt;
                }
                .titleGoogle {
                    margin-top: 20px;
                    color: var(--color-white);
                    margin-bottom: 30px;
                    font-size: 44pt;
                }
                .subtitle {
                    color: var(--color-white);
                    margin-bottom: 40px;
                    font-size: 14pt;
                    font-weight: 500;
                    text-align: left;
                }
            }
         }
    }

    &.banner-style-fb {
        
        margin: 160px 95px 0;
        padding: 250px 0 300px 200px;
        background: linear-gradient(45deg,  #1877F2,#4267B2, #3b5998, #14337c , #272727);
        background-size: 600% 100%;
        animation: gradient 16s linear infinite;
        animation-direction: alternate;
        border-radius: 60px;
        overflow: hidden;
        
        @media #{$laptop-device} {
            padding: 150px 0 200px 100px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 150px 60px;
            min-height: 600px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 80px 30px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        @keyframes gradient {
            0% {background-position: 0%}
            100% {background-position: 100%}
        }
        .banner-content {
            margin-bottom: 0;
            .title {
                margin-top: -80px;
                color: var(--color-white);
                margin-bottom: 30px;
                font-size: 57pt;
            }
            .subtitle {
                color: var(--color-white);
                margin-bottom: 40px;
                font-size: 13pt;
                font-weight: 500;
            }
        }
        @media (max-width: 900px) { 
            .hidemobile {
                display: none;
            }

            .banner-content {
                margin-bottom: 0;
                .title {
                    margin-top: 00px;
                    color: var(--color-white);
                    margin-bottom: 00px;
                    font-size: 34pt;
                }
                .titleGoogle {
                    margin-top: 20px;
                    color: var(--color-white);
                    margin-bottom: 30px;
                    font-size: 52pt;
                }
                .subtitle {
                    color: var(--color-white);
                    margin-bottom: 40px;
                    font-size: 14pt;
                    font-weight: 500;
                    text-align: left;
                }
            }
         }
    }
    &.banner-style-generic {
        
        margin: 160px 95px 0;
        padding: 250px 0 300px 200px;
        background: linear-gradient(45deg,  #c02135,#d83044, #f24055, #2e2046);
        background-size: 600% 100%;
        animation: gradient 16s linear infinite;
        animation-direction: alternate;
        border-radius: 60px;
        overflow: hidden;
        
        @media #{$laptop-device} {
            padding: 150px 0 200px 100px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 150px 60px;
            min-height: 600px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 80px 30px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        @keyframes gradient {
            0% {background-position: 0%}
            100% {background-position: 100%}
        }

        .banner-content {
            margin-bottom: 0;
            .title {
                margin-top: -80px;
                color: var(--color-white);
                margin-bottom: 30px;
                font-size: 57pt;
            }
            .subtitle {
                color: var(--color-white);
                margin-bottom: 40px;
                font-size: 13pt;
                font-weight: 500;
            }
        }
        @media  (max-width: 1080px) {

            .banner-content {
                margin-bottom: 0;
                .title {
                    margin-top: -40px;
                    color: var(--color-white);
                    margin-bottom: 30px;
                    font-size: 32pt;
                }
                .subtitle {
                    color: var(--color-white);
                    margin-bottom: 40px;
                    font-size: 13pt;
                    font-weight: 500;
                }
            }
        }
    }
    &.banner-style-ig {
        
        margin: 160px 95px 0;
        padding: 250px 0 300px 200px;
        background: linear-gradient(45deg,  #ffd600,#ff7a00, #ff0069, #d300c5 , #7638fa);
        background-size: 200% 100%;
        animation: gradient 6s linear infinite;
        animation-direction: alternate;
        border-radius: 60px;
        overflow: hidden;
          
        @media #{$laptop-device} {
            padding: 150px 0 200px 100px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 150px 60px;
            min-height: 600px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 80px 30px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        @keyframes gradient {
            0% {background-position: 0%}
            100% {background-position: 100%}
        }
        .banner-content {
            margin-bottom: 0;
            .title {
                margin-top: -80px;
                color: var(--color-white);
                margin-bottom: 30px;
                font-size: 57pt;
            }
            .subtitle {
                color: var(--color-white);
                margin-bottom: 40px;
                font-size: 13pt;
                font-weight: 500;
            }
        }
        @media (max-width: 900px) { 
            .hidemobile {
                display: none;
            }

            .banner-content {
                margin-bottom: 0;
                .title {
                    margin-top: 00px;
                    color: var(--color-white);
                    margin-bottom: 00px;
                    font-size: 34pt;
                }
                .titleGoogle {
                    margin-top: 20px;
                    color: var(--color-white);
                    margin-bottom: 30px;
                    font-size: 52pt;
                }
                .subtitle {
                    color: var(--color-white);
                    margin-bottom: 40px;
                    font-size: 14pt;
                    font-weight: 500;
                    text-align: left;
                }
            }
         }
    }
    &.banner-style-3 {
        padding: 220px 0 210px;
        @media #{$laptop-device} {
            padding: 180px 0 160px;
        }
        @media #{$smlg-device} {
            padding: 200px 0 150px;
        }
        @media #{$sm-layout} {
            padding: 150px 0 100px;
        }
        .banner-content {
            position: relative;
            z-index: 1;
            .subtitle {
                font-size: 20px;
                font-weight: 700;
                color: var(--color-rose);
                letter-spacing: 0.10em;
                font-family: var(--font-primary);
                margin-bottom: 30px;
                @media #{$md-layout} {
                   font-size: 16px;
                }
            }
            .title {
                margin-bottom: 60px;
                @media #{$md-layout} {
                    margin-bottom: 40px;
                }
            }
        }
        .banner-thumbnail {
           margin-right: -120px;
           text-align: right;
            @media #{$laptop-device} {
                margin-right: 0;
            }
            @media #{$smlg-device} {
                margin-right: 0;
            }
            @media #{$md-layout} {
                text-align: left;
            }
        }
    }
    &.banner-style-4 {
        background: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
        padding: 310px 0 100px;
        @media #{$laptop-device} {
            padding: 250px 0 100px;
        }
        @media #{$smlg-device} {
            padding: 250px 0 100px;
        }
        @media #{$md-layout} {
            padding: 180px 0 80px;
            min-height: 100%;
        }
        @media #{$sm-layout} {
            padding: 150px 0 60px;
            
        }
        .banner-content {
            margin-bottom: 200px;
            position: relative;
            z-index: 1;
            @media #{$smlg-device} {
                margin-bottom: 100px;
            }
            @media #{$md-layout} {
                margin-bottom: 0;
                
            }
            .title {
                width: 60%;
                margin-bottom: 50px;
                @media #{$laptop-device} {
                    font-size: 70px;
                    width: 55%;
                }
                @media #{$smlg-device} {
                    font-size: 70px;
                }
                @media #{$md-layout} {
                    font-size: 60px;
                    width: 100%;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                    margin-bottom: 30px;
                }
            }
            p {
                font-size: var(--font-body-1);
                width: 50%;
                margin-bottom: 40px;
                @media #{$md-layout} {
                   width: 100%;
                }
            }
        }
        .banner-thumbnail {
            position: absolute;
            top: 45%;
            right: 0;
            transform: translateY(-50%);
            @media #{$laptop-device} {
                right: -300px;
                img {
                    width: 85%;
                }
            }
            @media #{$smlg-device} {
                right: -570px;
                img {
                    width: 60%;
                }
            }
            @media #{$md-layout} {
                position: initial;
                transform: translateY(0);
                text-align: center;
                margin: -20px 0 20px;
                img {
                    width: 100%;
                }
            }
            @media #{$large-mobile} {
                margin: 0 0 20px;
            }
        }
    }
    &.banner-style-5 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 310px 0 0;
        @media #{$laptop-device} {
            padding: 250px 0 0;
            min-height: 750px;
        }
        @media #{$smlg-device}{
            padding: 230px 0 0;
            min-height: 750px;
        }
        @media #{$md-layout}{
            padding: 170px 0 80px;
            min-height: 100%;
        }
        @media #{$sm-layout}{
            padding: 150px 0 60px;
            min-height: 100%;
        }
        &::after {
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgba(41,41,48,0.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
        .banner-content {
            @media #{$md-layout}{
                margin-bottom: 40px;
            }
            .title {
                color: var(--color-white);
                margin-bottom: 50px;
                @media #{$smlg-device} {
                   font-size: 70px;
                }
                @media #{$md-layout} {
                   font-size: 60px;
                }
                @media #{$sm-layout} {
                   font-size: 50px;
                }
                @media #{$large-mobile} {
                   font-size: 40px;
                   margin-bottom: 40px;
                }
            }
            .axil-btn {
                color: var(--color-text-dark);
                box-shadow: none;
            }
            .axil-btn:hover {
                color: var(--color-text-dark);
                box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px!important;
            }
            
        }
        .banner-form {
            display: flex;
            justify-content: flex-end;
            margin-bottom: -140px;
            @media #{$md-layout}{
                margin-bottom: 0;
                display: block;
            }
            .contact-form-box {
                max-width: 450px;
                @media #{$md-layout}{
                    max-width: 100%;
                }
                
            }
            .title {
                letter-spacing: -0.045em;
            }
        }
    }
}

.contact-hubspot{
    background: #f42f3f;
    padding: 2rem 3rem;
    color: white;
    border-radius: 35px;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
}

.contact-hubspot:hover{
    color: white;
    background: #a5202b;
    border-radius: 50px;
}