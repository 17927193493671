/*-------------------------
  Helper Classes
-------------------------*/


.bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-light {
    background-color: #F3F3F3;
}

.bg-color-blue {
    background: linear-gradient(45deg,#5956E9, #353399 );
    background-size: 600% 100%;
    animation: gradient 10s linear infinite;
    animation-direction: alternate;
}
@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}


.bg-color-white {
    background: linear-gradient(45deg,#ffffff, #d1d1d1 );
    background-size: 600% 100%;
    animation: gradient 10s linear infinite;
    animation-direction: alternate;
}
@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}

.bg-wallpapaer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}



.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}

// Color
.color-gray-4 {
    color: var(--color-gray-4);
}

// Opacity
.opacity-50 {
    opacity: 0.5;
}