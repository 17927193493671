
@media (max-width: 1300px) {
    .hidemobile {
        display: none;
    }
    .growthButton {
        margin: 0 auto;
    }
.banner-style-1 {
    margin-bottom: 100px;
}
    
}

@media (min-width: 1301px),
       (max-width: 1401px) {
        .hidedesktop {
            display: none;
        }
        .growthButton {
            margin-left: 19.6%;
            margin-top: -14.5%;
        }
    }




@media (min-width: 1900px) {
    .hidedesktop {
        display: none;
    }

    .growthButton {
        transform: scale(1.5);
        margin-left: 35.6%;
        margin-top: -12.5%;

    }
}